<template>
	<b-modal ref="modalLocalisation" @shown="modalShown" static>
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('localisation.ajouter') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-12">
					<label for="libelle">{{ $t('localisation.lieu') }}</label>
					<e-select
						v-model="lieu"
						id="lieu_id"
						track-by="lieu_label"
						label="lieu_label"
						:placeholder="$t('localisation.rechercher_lieu')"
						:selectedLabel="$t('global.selected_label')"
						:options="lieux"
						:searchable="true"
						:show-labels="false"
						:allow-empty="true"
						class="form-group"
					>
					</e-select>
				</div>

				<div class="col-md-6 col-12">
					<div class="form-group">
						<label for="libelle">{{ $t('localisation.libelle') }}</label>
						<input
							id="libelle"
							type="text"
							class="form-control"
							:placeholder="$t('localisation.libelle')"
							v-model="libelle"
							:class="{ 'is-invalid': errors && errors.indexOf('libelle') > -1 }"
						>
					</div>
				</div>

				<div class="col-md-6 col-12">
					<div class="form-group">
						<label for="nb_chevaux">{{ $t('localisation.nb_chevaux') }}</label>
						<input
							id="nb_chevaux"
							type="text"
							class="form-control"
							:placeholder="$t('localisation.nb_chevaux')"
							v-model="nb_chevaux"
							:class="{ 'is-invalid': errors && errors.indexOf('nb_chevaux') > -1 }"
						>
					</div>
				</div>

				<div class="col-12">
					<label for="place">{{ $t('localisation.recherche_adresse') }}</label>
					<places 
						ref="places"
						:placeholder="$t('localisation.adresse')" 
			        	:options="options"
			        	v-model="place"
			        	@change="changePlaceGeo"
			        />

					<b-button variant="secondary" @click="geoloc()" style="height:40px;">
						<font-awesome-icon v-if="load_geoloc" :icon="['fas', 'spinner']" pulse/>
						<font-awesome-icon v-else :icon="['far', 'location']" />
					</b-button>
				</div>	

			</div>

			<div class="mt-2">
				<b>{{ $t('localisation.message_map') }}</b>
			</div>


			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
				    <ErrorAlert :messageI18n="form_message" />
				</div>
			</div>
			
			<l-map 
				id="map-modal"
				ref="map"
				:zoom="zoom" 
				:center="center"
				@click="addMarker"
				class="mt-3"
			>
				<l-tile-layer
					:url="url"
					:attribution="attribution"
				/>
				<l-marker v-if="position" :lat-lng="position">
				</l-marker>
			</l-map>

		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">

	import { LMap, LTileLayer, LMarker, LControl, LPopup } from 'vue2-leaflet';
	import { Icon } from 'leaflet';
	import L from 'leaflet';
	import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
	import 'leaflet/dist/leaflet.css';
	import Places from 'vue-places'
	import Localisation from "@/mixins/Localisation.js"

	export default {
		name: 'ModalLocalisation',
		mixins: [Localisation],
		props: ['localisation', 'refresh_table'],
		data () {
			return {
				form_message: '', 
				processing: false, 
				libelle: null,
				nb_chevaux: null,
				position: null,
				lieu: null,

				errors: [],
				lieux: [],

				zoom: 5,
				center: L.latLng(46.790, 2.703),
				url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
				attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

				load_geoloc: false,
				place: '',
				options: {
					appId: 'pl3Q2DZM10M4',
					apiKey: '2c5ae0f358e1baa5bc784a1d267a91d9'
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.localisation.id !== null) {
					this.init_marker()
				}

				delete Icon.Default.prototype._getIconUrl;
				Icon.Default.mergeOptions({
					iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
					iconUrl: require('leaflet/dist/images/marker-icon.png'),
					shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
				});

				this.lieux = await this.getLieux()
				if(this.localisation.id !== null) {
					this.preselectLieu()
				}

			},

			init_marker() {
				this.libelle = this.localisation.libelle
				this.nb_chevaux = this.localisation.nb_horse
				this.position = L.latLng(this.localisation.lat, this.localisation.lng)
				this.center = this.position
				this.zoom = 15
			},

			reset() {
				this.libelle = ""
				this.nb_chevaux = ""
				this.position = null
				this.position = L.latLng(46.790, 2.703)
				this.center = this.position
				this.zoom = 6
			},

			preselectLieu(){
				this.lieu = this.lieux.filter(lieu => lieu.lieu_id == this.localisation.lieu)[0]
			},

			addMarker(event) {
				this.position = event.latlng
			},

			async checkForm() {
				this.processing = true;
				this.form_message = ''
				this.errors = []

				if(this.libelle == null) {
					this.errors.push("libelle")
				}

				if(this.nb_chevaux == null) {
					this.errors.push("nb_chevaux")
				}
				if(this.position == null){
					this.form_message = 'localisation.error_position'
					this.errors.push("position")
				}

				if(this.errors.length > 0){
					this.processing = false
					return false
				}

				const param = {
					lieu: (this.lieu !== null) ? this.lieu.lieu_id : null,
					libelle: this.libelle,
					nb_chevaux: this.nb_chevaux,
					lat: this.position.lat,
					lng: this.position.lng
				}

				if(this.localisation.id) {
					param.id = this.localisation.id
				}

				const res = await this.saveLocalisation(param)

				if(res) {
					this.successToast()
					this.$refs.modalLocalisation.hide()
					this.reset()
					this.processing = false;
					this.$emit('update:refresh_table')
				} 
				else {
					this.processing = false;
					this.failureToast();
				}
			},

			onLocationFound(e) {
				this.load_geoloc = false
			    this.position = e.latlng
			},

			onLocationError(e) {
				this.load_geoloc = false
			},
			geoloc() {
				this.load_geoloc = true;
				this.$refs.map.mapObject.locate({setView: true, maxZoom: 16});
				this.$refs.map.mapObject.on('locationfound', this.onLocationFound);
				this.$refs.map.mapObject.on('locationerror', this.onLocationError);
			},

		    changePlaceGeo(val) {
		    	if(!val.latlng) return false
				this.position = L.latLng(val.latlng.lat, val.latlng.lng)
		    	this.center = L.latLng(val.latlng.lat, val.latlng.lng)
		    	this.zoom = 14
		    	this.$refs.map.mapObject.setView(this.center, this.zoom);
		    },
			
			openModal() {
				this.$refs.modalLocalisation.show()
			},

			modalShown() {
				setTimeout(() => {
					this.$refs.map.mapObject.invalidateSize(); 
				}, 100);
		    }
		},

		watch: {
			localisation: {
			    handler(val){
					if(val.id) {
						this.init_marker()
						this.preselectLieu()
					}
					else {
						this.reset()
					}
			    },
			    deep: true
			}
		},

		components: {
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			LMap,
			LTileLayer,
			LMarker,
			LControl,
			LPopup,
			'l-icon': Icon,
			'v-marker-cluster':Vue2LeafletMarkerCluster,
			Places
		}
	}

</script>

<style>
	@import "~leaflet.markercluster/dist/MarkerCluster.css";
	@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
	.leaflet-pane, .leaflet-control-container .leaflet-top{
		z-index: 1;
	}
	#map-modal{
		width: 100%;
		height: 350px;
	}
	.algolia-places{
		width: calc(100% - 42px);
		line-height: 2.3;
	}
</style>